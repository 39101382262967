.delivery {
    background-color: #f5e7c1;

    .delivery__hero {
        width: 100%;
        background-image: url("../../images/hero_contact.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        height: 700px;
        position: relative;

        .delivery__title {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 700px;

            h1 {
                font-size: 110px;
                font-weight: 400;
                font-family: "Rakkas", serif;
                color: #fff;
                text-align: center;
                margin: 0;
                line-height: 108px;

                @media only screen and (max-width: 846px) {
                    font-size: 90px;
                    line-height: 90px;
                    margin-bottom: 35px;
                }
            }

            p {
                font-size: 26px;
                color: #fff;
                text-align: center;
                margin: 0;

                @media only screen and (max-width: 846px) {
                    font-size: 24px;
                }
            }
        }

        .creambottom {
            position: absolute;
            bottom: -1px;
            width: 100%;
            margin: 0;
        }
    }

    .other__options {
        padding-bottom: 180px;
        position: relative;

        h3 {
            text-align: center;
            font-size: 24px;
        }

        .delivery__cards {
            max-width: 800px;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 10px;
            justify-content: space-between;
            margin-left: auto;
            margin-right: auto;

            .img__card {
                overflow: hidden;
                border-radius: 30px;
                height: 175px;

                img {
                    width: 175px;
                    max-width: 175px;
                    border-radius: 30px;
                    transition: 0.3s;

                    &:hover {
                        scale: 1.05;
                        transition: 0.3s;
                    }
                }

                @media only screen and (max-width: 760px) {
                    display: flex;
                    justify-content: center;
                }
            }

            @media only screen and (max-width: 760px) {
                grid-template-columns: repeat(2, 1fr);
            }
        }

        .redbottom {
            position: absolute;
            bottom: -1px;
            width: 100%;
        }
    }

    .trends {
        background-color: #e84242;
        padding-top: 30px;
        padding-bottom: 120px;

        h1 {
            font-size: 110px;
            font-family: "Rakkas", serif;
            color: #f5e7c1;
            margin: 0;
            text-align: center;
            margin-bottom: 40px;

            @media only screen and (max-width: 760px) {
                font-size: 90px;
            }
        }

        .mini__card {
            background-color: #fff;
            padding: 30px;
            border-radius: 30px;
            margin-bottom: 20px;

            h4 {
                text-transform: uppercase;
                font-size: 13px;
                margin-top: 0;
                margin-bottom: 4px;
                font-weight: 600;
            }

            h3 {
                margin-top: 0;
                font-size: 30px;
                font-weight: 900;
                margin-bottom: 4px;
            }

            img {
                width: 100%;
            }

            div {
                h5 {
                    text-transform: uppercase;
                    font-size: 30px;
                    margin-top: 0;
                    margin-bottom: 4px;
                }

                span {
                    font-size: 14px;
                    font-weight: 600;
                    color: #bcbcbc;
                }
            }

            .add {
                button {
                    cursor: pointer;
                    border: 2px solid #e84242;
                    background: white;
                    font-size: 50px;
                    color: #e84242;
                    border-radius: 50%;
                    padding: 12px;
                    border-radius: 50%;
                    justify-content: center;
                    display: flex;
                    height: 60px;
                    width: 60px;
                    transition: 0.3s;
                    line-height: 25px;
                    font-weight: 300;

                    &:hover {
                        background-color: #e84242;
                        color: #ffffff;
                    }
                }
            }
        }
    }
}
