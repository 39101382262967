.shop {
    background-color: #f5e7c1;

    .shop__hero {
        width: 100%;
        background-image: url("../../images/hero_shop.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        height: 700px;
        position: relative;

        .hero__title {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 700px;

            h1 {
                font-size: 110px;
                font-weight: 400;
                font-family: "Rakkas", serif;
                color: #fff;
                text-align: center;
                margin: 0;

                @media only screen and (max-width: 846px) {
                    font-size: 90px;
                    line-height: 90px;
                    margin-bottom: 35px;
                }
            }

            p {
                font-size: 26px;
                color: #fff;
                text-align: center;
                margin: 0;

                @media only screen and (max-width: 846px) {
                    font-size: 24px;
                }
            }
        }

        .creambottom {
            position: absolute;
            width: 100%;

            @media only screen and (max-width: 1200px) {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

    .products {
        padding-top: 128px;
        padding-bottom: 128px;

        .first {
            .mobile-col {
                .second {
                    @media only screen and (max-width: 1200px) {
                        margin: 0;
                    }
                }

                @media only screen and (max-width: 1200px) {
                    // width: calc(100% - 30px);
                    width: 100%;
                    margin: 0;
                }
            }

            @media only screen and (max-width: 1200px) {
                width: auto;
                margin: 0;
            }

            .second{
                @media only screen and (max-width: 1200px) {
                    width: 100%;
                }
            }

            .mobile-w{
                @media only screen and (max-width: 1200px) {
                    width: calc(100% - 30px);
                }
            }
        }

        p{
            @media only screen and (max-width: 1200px) {
                margin-left: 15px;
            }
        }
    }

    .card {
        background-color: #fff;
        padding: 32px;
        border-radius: 30px;
        min-height: 520px;
        position: relative;

        h4 {
            font-size: 13px;
            text-transform: uppercase;
            font-weight: 600;
            margin: 0;
            margin-bottom: 16px;

            @media only screen and (max-width: 1200px) {
                margin-top: 25px;
            }
        }

        h3 {
            font-size: 30px;
            margin: 0;
        }

        .price {
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 600;
            margin-top: 5px;
            position: absolute;
            bottom: 85px;

            @media only screen and (max-width: 1200px) {
                position: static;
            }
        }

        button {
            border: none;
            text-transform: uppercase;
            background-color: #e84242;
            font-size: 14px;
            font-weight: 600;
            color: #fff;
            padding: 16px 22px;
            border-radius: 50px;
            position: absolute;
            bottom: 32px;
            cursor: pointer;
        }

        @media only screen and (max-width: 1200px) {
            min-height: 600px;
        }
    }

    .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 25px;
        margin-top: 35px;

        svg {
            cursor: pointer;
        }

        .pages {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;

            span {
                font-size: 16px;
                font-weight: 600;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 15px;
                border-radius: 50px;
                transition: 0.5s;
                cursor: pointer;

                &:hover {
                    background-color: #000;
                    color: #fff;
                    transition: 0.5s;
                }
            }

            .active-page {
                color: #fff;
                background-color: #000;
            }
        }
    }

    .cart {
        h3 {
            font-family: "Rakkas", serif;
            font-weight: 400;
            margin-bottom: 0;
            font-size: 30px;
        }

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                padding-top: 16px;
                padding-bottom: 16px;
                border-bottom: 1px solid #dccfad;

                // .space-between{
                //     @media only screen and (max-width: 1200px) {
                //         justify-content: space-between;
                //     }
                // }

                .relative{
                    position: relative;
                }

                img {
                    max-width: 75px;
                    max-height: 75px;
                    border-radius: 20px;
                }

                .justify-center {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    div {
                        padding-left: 10px;
                    }
                }

                .product-title {
                    font-size: 16px;
                    font-family: "Inter", Arial, Helvetica, sans-serif;
                    font-weight: 800;
                    margin-top: 0;
                    margin-bottom: 4px;
                }

                .product-quantity {
                    margin: 0;
                }

                svg {
                    cursor: pointer;
                    position: absolute;
                    right: 0;
                }
            }
        }

        .subtotal {
            justify-content: space-between;

            .subtotal-paragraph {
                font-size: 16px;
                font-weight: 700;
            }

            .subtotal-price {
                font-size: 16px;
                font-weight: 400;
            }
        }

        .buttons {
            justify-content: space-between;

            a {
                color: #fff;
                text-transform: uppercase;
                padding: 16px 22px;
                background-color: #e84242;
                border: none;
                border-radius: 50px;
                font-size: 14px;
                font-weight: 600;
                cursor: pointer;
                text-decoration: none;

                &:hover {
                    box-shadow: 0 0 0 4em #e84242 inset;
                }
            }
        }
    }
}
