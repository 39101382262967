.checkout {
    background-color: #f5e7c1;

    .checkout__hero {
        width: 100%;
        background-image: url("../../images/hero_shop.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        height: 700px;
        position: relative;

        .checkout__title {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 700px;

            h1 {
                font-size: 110px;
                font-weight: 400;
                font-family: "Rakkas", serif;
                color: #fff;
                text-align: center;
                margin: 0;
                margin-left: 15px;
                margin-right: 15px;

                @media only screen and (max-width: 846px) {
                    font-size: 90px;
                    line-height: 90px;
                    margin-bottom: 35px;
                }

                @media only screen and (max-width: 372px) {
                    font-size: 70px;
                    line-height: 70px;
                }
            }

            .text {
                font-size: 26px;
                color: #fff;
                text-align: center;
                margin: 0;
                margin-left: 15px;
                margin-right: 15px;

                @media only screen and (max-width: 846px) {
                    font-size: 24px;
                }
            }
        }

        .creambottom {
            position: absolute;
            bottom: -1px;
            width: 100%;

            @media only screen and (max-width: 1200px) {
                margin-right: 0;
                margin-left: 0;
            }
        }
    }

    .checkout-products {
        padding-top: 128px;
        padding-bottom: 128px;

        .checkout-details {
            padding: 50px;
            background-color: #fff;
            border-radius: 50px;

            form {
                label,
                input {
                    display: block;
                }

                label {
                    margin-bottom: 12px;

                    span {
                        color: red;
                        font-weight: 700;
                    }
                }

                input{
                    font-size: 14px;
                }

                input,
                select {
                    width: 100%;
                    padding: 13px 20px;
                    border: 2px solid #e5e5e5;
                    border-radius: 50px;
                    transition: 0.3s;
                    outline: none;
                    margin-bottom: 16px;

                    &:focus {
                        border: 2px solid #e84242;
                        transition: 0.3s;
                    }
                }

                h2 {
                    font-family: "Rakkas", serif;
                    font-weight: 400;
                    font-size: 30px;
                    // margin-bottom: 13px;
                    // margin-top: 14px;
                    margin: 0;

                    @media only screen and (max-width: 430px) {
                        font-size: 22px;
                    }
                }

                select {
                    &:after {
                        right: 15px;
                        position: absolute;
                    }
                }

                .shipto {
                    .check {
                        width: auto;
                        padding: initial;
                        border-radius: initial;
                        border: none;
                        margin-right: 10px;
                        margin-bottom: 3px;
                        height: 13px;
                        margin-top: 12px;
                    }

                    .different-address {
                        height: 0;
                        overflow: hidden;
                        margin-bottom: 15px;
                        transition: 0.8s;

                        textarea {
                            resize: none;
                            width: 100%;
                            padding: 13px 20px;
                            border: 2px solid #e5e5e5;
                            border-radius: 20px;
                            transition: 0.3s;
                            outline: none;
                            max-height: 140px;

                            &:focus {
                                border: 2px solid #e84242;
                                transition: 0.3s;
                            }
                        }
                    }

                    textarea {
                        resize: none;
                        width: 100%;
                        padding: 13px 20px;
                        border: 2px solid #e5e5e5;
                        border-radius: 20px;
                        transition: 0.3s;
                        outline: none;
                        max-height: 140px;

                        &:focus {
                            border: 2px solid #e84242;
                            transition: 0.3s;
                        }
                    }

                    .different-address.visible {
                        height: 655px;
                        transition: 0.8s;
                        overflow: hidden;
                    }

                    .label-container {
                        margin-bottom: 0;
                    }
                }
            }

            @media only screen and (max-width: 550px) {
                padding-left: 25px;
                padding-right: 25px;
            }

            h3 {
                font-size: 30px;
                font-weight: 400;
                font-family: "Rakkas", serif;
            }

            .product-table {
                .table-head {
                    background-color: #e84242;
                    display: flex;
                    justify-content: space-between;
                    padding: 16px 32px;
                    border-radius: 50px;

                    span {
                        font-weight: 700;
                        color: #fff;
                        font-size: 16px;
                    }
                }

                .table-body {
                    .products {
                        .cart-item {
                            .cart-item-details {
                                display: flex;
                                justify-content: space-between;
                                padding: 16px;
                                border-bottom: 2px solid #e5e5e5;

                                p {
                                    margin: 0;
                                    font-size: 14px;
                                    font-weight: 400;
                                }
                            }
                        }
                    }

                    .total {
                        display: flex;
                        justify-content: space-between;
                        padding: 16px;
                        border-bottom: 2px solid #e5e5e5;

                        p {
                            margin: 0;
                            font-size: 14px;
                            font-weight: 400;
                        }

                        .total-amount {
                            font-weight: 700;
                        }
                    }
                }
            }

            .radiobuttons {
                .flex {
                    display: flex;
                    margin-bottom: 10px;

                    .radiobutton {
                        width: 12px;
                        height: 12px;
                        margin-top: 3px;
                        margin-right: 7px;
                        margin-bottom: 0;
                    }

                    label {
                        margin: 0;
                        font-size: 15px;
                    }

                    &:first-child {
                        margin-top: 35px;
                    }
                }

                .information-sheet {
                    margin: 15px 0;
                    padding: 12px 16px;
                    background-color: #ededed;
                    border-radius: 15px;
                    font-size: 14px;
                    font-weight: 400;
                    position: relative;
                }

                .information-sheet::before {
                    content: "";
                    display: block;
                    border: 10px solid rgba(0, 0, 0, 0.07);
                    border-right-color: transparent;
                    border-left-color: transparent;
                    border-top-color: transparent;
                    position: absolute;
                    left: 2em;
                    top: -20px;
                }
            }

            .captcha {
                margin-top: 20px;
            }

            .submit {
                display: flex;
                justify-content: center;

                button {
                    padding: 12px 42px;
                    border-radius: 50px;
                    font-size: 14px;
                    border: none;
                    text-transform: uppercase;
                    color: #fff;
                    background-color: #e84242;
                    font-weight: 600;
                    cursor: pointer;
                    transition: 0.3s;

                    &:hover {
                        background-color: #ff4949;
                        transition: 0.3s;
                    }
                }
            }
        }
    }
}
