.contact {
    background-color: #e84242;
    padding-bottom: 130px;

    .contact__hero {
        width: 100%;
        background-image: url("../../images/hero_contact.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        height: 700px;
        position: relative;

        .contact__title {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 700px;

            h1 {
                font-size: 110px;
                font-weight: 400;
                font-family: "Rakkas", serif;
                color: #fff;
                text-align: center;
                margin: 0;
                margin-left: 15px;
                margin-right: 15px;

                @media only screen and (max-width: 846px) {
                    font-size: 90px;
                    line-height: 90px;
                    margin-bottom: 35px;
                }

                @media only screen and (max-width: 372px) {
                    font-size: 58px;
                    line-height: 60px;
                }
            }

            .text {
                font-size: 26px;
                color: #fff;
                text-align: center;
                margin: 0;
                margin-left: 15px;
                margin-right: 15px;

                @media only screen and (max-width: 846px) {
                    font-size: 24px;
                }
            }
        }

        .creambottom {
            position: absolute;
            bottom: 0;
            width: 100%;
        }
    }

    .contact__title {
        text-align: center;
        font-family: "Rakkas", serif;
        color: #f5e7c1;
        margin: 0;
        font-size: 80px;
        font-weight: 400;
        margin-left: 15px;
        margin-right: 15px;
        padding-top: 80px;

        @media only screen and (max-width: 660px) {
            font-size: 60px;
            line-height: 60px;
        }
    }

    .paragraph__title {
        font-size: 25px;
        font-weight: 400;
        margin-top: 20px;
        margin-bottom: 70px;
        color: #f5e7c1;
        text-align: center;
        margin-left: 15px;
        margin-right: 15px;

        @media only screen and (max-width: 660px) {
            font-size: 22px;
        }
    }

    .question__title {
        align-items: center;
        gap: 10px;
        color: #f5e7c1;
        transition: 0.3s;
        cursor: pointer;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 2px solid #fff;

        &:hover {
            transition: 0.3s;
            color: #fff;
        }

        svg {
            font-size: 20px;
            transition: 0.3s;
        }

        h2 {
            margin: 0;
        }
    }

    .active__arrow {
        color: #fff;

        svg {
            transform: rotate(90deg);
            transition: 0.3s;
        }
    }

    // p {
    //     color: #fff;
    //     font-size: 16px;
    //     line-height: 28px;
    //     margin-bottom: 8px;
    //     margin-top: 8px;
    //     height: 0;
    //     overflow: hidden;
    //     transition: .3s;
    // }

    .active__text {
        height: 110px !important;
        transition: 0.3s;
    }

    .personal__card {
        border-radius: 50px;
        border: 2px solid #fff;
        padding: 40px 25px 60px 25px;
        display: grid;
        gap: 20px;

        input,
        textarea {
            width: 100%;
            padding: 13px 20px;
            border-radius: 50px;
            border: none;
            outline: none;
            border: 2px solid transparent;
            transition: 0.5s;

            &:focus {
                border: 2px solid #000;
                transition: 0.5s;
            }

            &::placeholder {
                font-size: 16px;
                font-weight: 500;
                color: #969696;
            }
        }

        textarea {
            border-radius: 20px;
            font-size: 16px;
        }

        button {
            text-transform: uppercase;
            border: 2px solid #fff;
            background-color: transparent;
            cursor: pointer;
            border-radius: 50px;
            color: #fff;
            font-size: 16px;
            padding: 18px 40px;
            font-weight: 600;
            transition: 0.5s;

            &:hover {
                transition: 0.5s;
                background-color: #fff;
                color: #e84242;
            }
        }
    }

    .questions p {
            color: #fff;
            font-size: 16px;
            line-height: 28px;
            margin-bottom: 8px;
            margin-top: 8px;
            height: 0;
            overflow: hidden;
            transition: .3s;
    }
}


.questions{
    @media only screen and (max-width: 998px) {
        margin-bottom: 60px;
    }
}