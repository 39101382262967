.follows {
    position: relative;
    width: 100%;
    height: 350px;
    background-image: url("../../images/follows.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    justify-content: center;

    .redtop {
        position: absolute;
        top: -1px;
    }

    img {
        width: 100%;
    }

    .social {
        display: flex;
        align-items: center;
        justify-content: center;

        .giveUsAFollow {
            justify-content: center;
            display: flex;
            align-items: center;

            h5 {
                margin: 0;
                font-size: 24px;
                font-weight: 700;
                color: #fff;
                padding-left: 10px;
                padding-right: 10px;

                @media only screen and (max-width: 450px) {
                    text-align: center;
                    margin-bottom: 30px;
                }
            }

            .socialIcons {
                padding-left: 10px;
                padding-right: 10px;
                display: flex;
                gap: 8px;
                align-items: center;

                a {
                    background-color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 20px;
                    border-radius: 50%;
                    font-size: 26px;
                    color: #e84242;
                    transition: 0.5s;

                    &:hover {
                        transition: 0.5s;
                        color: #fff;
                        background-color: #e84242 !important;
                    }
                }                
            }

            @media only screen and (max-width: 450px) {
                display: block;
            }
        }
    }

    .bottom-img {
        position: absolute;
        bottom: -1px;
    }
}
