.cart {
    background-color: #f5e7c1;

    .cart__hero {
        width: 100%;
        background-image: url("../../images/hero_shop.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        height: 700px;
        position: relative;

        .cart__title {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 700px;

            h1 {
                font-size: 110px;
                font-weight: 400;
                font-family: "Rakkas", serif;
                color: #fff;
                text-align: center;
                margin: 0;
                margin-left: 15px;
                margin-right: 15px;

                @media only screen and (max-width: 846px) {
                    font-size: 90px;
                    line-height: 90px;
                    margin-bottom: 35px;
                }

                @media only screen and (max-width: 372px) {
                    font-size: 58px;
                    line-height: 60px;
                }
            }

            .text {
                font-size: 26px;
                color: #fff;
                text-align: center;
                margin: 0;
                margin-left: 15px;
                margin-right: 15px;

                @media only screen and (max-width: 846px) {
                    font-size: 24px;
                }
            }
        }

        .creambottom {
            position: absolute;
            bottom: -1px;
            width: 100%;

            @media only screen and (max-width: 1200px) {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

    .cart-products-desktop {
        padding-top: 128px;
        padding-bottom: 128px;

        .product-details {
            padding: 50px;
            background-color: #fff;
            border-radius: 50px;

            table {
                border-collapse: collapse;
                width: 100%;

                tbody{
                    tr {
                        text-align: center;
                    }
                }

                tr {
                    border-bottom: 1px solid #ededed;

                    th {
                        background-color: #e84242;
                        padding: 16px;

                        &:first-child {
                            border-top-left-radius: 50px;
                            border-bottom-left-radius: 50px;
                        }

                        &:last-child {
                            border-top-right-radius: 50px;
                            border-bottom-right-radius: 50px;
                        }
                    }

                    &:first-child {
                        border-radius: 15px;

                        th {
                            color: #fff;
                        }
                    }

                    &:not(:first-child) {
                        text-align: center;
                    }

                    td {
                        padding: 16px;

                        input {
                            padding: 13px 20px;
                            width: 90px;
                            border-radius: 30px;
                            border: 2px solid #ededed;
                            outline: none;
                            transition: 0.5s;

                            &:focus {
                                transition: 0.5s;
                                border: 2px solid #e84242;
                            }
                        }

                        svg {
                            cursor: pointer;
                        }

                        img {
                            width: 68px;
                            height: 68px;
                        }
                    }

                    &:first-child {
                        border-bottom: none;
                    }
                }

                .no-product {
                    .information {
                        display: flex;
                        background-color: #f4f8ff;
                        gap: 15px;
                        border: 1px solid #007cba;
                        border-radius: 5px;
                        align-items: center;
                        padding: 16px;
                        margin-bottom: 12px;

                        svg {
                            color: #007cba;
                            width: 24px;
                            height: 24px;
                        }

                        span {
                            font-size: 14px;
                        }
                    }
                    a {
                        text-decoration: none;
                        text-transform: uppercase;
                        color: #fff;
                        background-color: #e84242;
                        font-size: 14px;
                        font-weight: 600;
                        padding: 20px 22px;
                        border-radius: 50px;
                        display: inline-block;
                    }
                }
            }

            .cart-totals {
                h2 {
                    font-size: 30px;
                    font-family: "Rakkas", serif;
                    font-weight: 400;
                }
                .details {
                    border-bottom: 3px solid #ededed;
                    border-top: 3px solid #ededed;

                    .flex {
                        display: flex;
                        justify-content: space-between;

                        .price {
                            font-weight: 700;
                        }

                        &:first-child {
                            border-bottom: 1px solid #ededed;
                        }
                    }
                }

                .text-center {
                    text-align: center;
                    margin-top: 50px;

                    a {
                        color: #fff;
                        background-color: #e84242;
                        text-transform: uppercase;
                        text-decoration: none;
                        font-size: 14px;
                        font-weight: 700;
                        padding: 20px 22px;
                        border-radius: 50px;
                    }
                }
            }
        }

        @media only screen and (max-width: 710px) {
            display: none;
        }
    }

    .cart-products-mobile {
        margin-left: 10px;
        margin-right: 10px;
        padding-top: 120px;
        padding-bottom: 60px;

        .wrapper-container-mobile {
            background-color: #fff;
            border-radius: 50px;

            .table-mobile {
                border-radius: 50px;
                padding: 0px 20px;
                padding-bottom: 30px;

                .product-mobile {
                    position: relative;
                    padding-top: 50px;
                    padding-bottom: 50px;
                    border-bottom: 3px solid #ededed;

                    svg {
                        position: absolute;
                        // left: 30px;
                        top: 25px;
                        font-size: 26px;
                        color: red;
                    }

                    img {
                        margin-top: 50px;
                        object-fit: cover;
                        width: 100%;
                    }

                    div {
                        padding-top: 15px;

                        strong {
                            margin-right: 5px;
                        }

                        span {
                            color: #e84242;
                        }

                        input {
                            padding: 13px 20px;
                            width: 90px;
                            border-radius: 30px;
                            border: 2px solid #ededed;
                            outline: none;
                            transition: 0.5s;

                            &:focus {
                                transition: 0.5s;
                                border: 2px solid #e84242;
                            }
                        }
                    }
                }

                h2{
                    border-bottom: 3px solid #ededed;
                    font-family: "Rakkas", serif;
                    padding-bottom: 18px;
                }

                .shipping-mobile,
                .total-mobile{
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 20px;
                    padding-top: 20px;

                    p{
                        margin: 0;
                    }
                }

                .shipping-mobile{
                    border-bottom: 1px solid #ededed;
                }

                .total-mobile{
                    border-bottom: 3px solid #ededed;
                }

                .to-checkout{
                    text-align: center;
                    margin-top: 25px;

                    a{
                        text-decoration: none;
                        text-transform: uppercase;
                        color: #fff;
                        background-color: #e84242;
                        font-size: 14px;
                        font-weight: 600;
                        padding: 16px 22px;
                        border-radius: 50px;
                        display: inline-block;
                    }
                }
            }
        }

        @media only screen and (min-width: 710px) {
            display: none;
        }
    }
}
