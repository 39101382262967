.home {
    padding-top: 192px;
    padding-bottom: 192px;
    background-color: #e84242;

    h1 {
        font-size: 22vw;
        margin: 0;
        text-align: center;
        font-family: "Rakkas", serif;
        line-height: 24vw;
        color: #f5e7c1;
        position: absolute;
        width: 100%;
        z-index: 1;
        margin-left: 15px;
        margin-right: 15px;

        @media only screen and (max-width: 1200px) {
            margin-top: 35px;
            width: auto;
        }
    }

    .burger {
        position: relative;
        max-width: 100%;
    }

    .leftfries {
        top: 700px;
        left: -200px;
        position: absolute;
        transition: 0.5s;
        // transform: translateX(-50%);
        animation: moveImage 4s linear infinite;
    }

    .rightfries {
        top: 300px;
        position: absolute;
        right: -200px;
        // transform: translateX(-50%);
        animation: myMove 4s linear infinite;
    }

    h2 {
        font-family: "Rakkas", serif;
        text-align: center;
        font-size: 110px;
        color: #f5e7c1;

        @media only screen and (max-width: 850px) {
            margin-top: 0;
            margin-bottom: 0;
        }

        @media only screen and (max-width: 600px) {
            font-size: 75px;
        }

        @media only screen and (max-width: 500px) {
            font-size: 62px;
        }
    }

    .card {
        padding: 30px;
        background-color: #ffffff;
        border-radius: 30px;

        h4 {
            text-transform: uppercase;
            font-size: 14px;
            margin-top: 0;
            margin-bottom: 4px;

            @media only screen and (max-width: 500px) {
                font-size: 12px;
            }
        }

        h3 {
            margin-top: 0;
            font-size: 40px;
            font-weight: 900;
            margin-bottom: 4px;

            @media only screen and (max-width: 380px) {
                font-size: 34px;
            }

            @media only screen and (max-width: 328px) {
                font-size: 32px;
            }
        }

        img {
            max-height: 300px;
        }

        h5 {
            text-transform: uppercase;
            font-size: 30px;
            margin-top: 0;
            margin-bottom: 4px;

            @media only screen and (max-width: 530px) {
                font-size: 26px;
            }
        }

        span {
            font-size: 14px;
            font-weight: 600;
            color: #bcbcbc;

            @media only screen and (max-width: 328px) {
                font-size: 12px;
            }
        }

        .add {
            display: flex;
            // justify-content: center;
            // align-items: center;
            // width: 60px;
            // height: 60px;
            // border: 2px solid #e84242;
            // cursor: pointer;
            // font-size: 40px;
            // border-radius: 50%;
            // transition: 300ms ease;

            button {
                cursor: pointer;
                border: 2px solid #e84242;
                background: white;
                font-size: 50px;
                color: #e84242;
                border-radius: 50%;
                padding: 12px;
                border-radius: 50%;
                // align-items: center;
                justify-content: center;
                display: flex;
                height: 60px;
                width: 60px;
                transition: 0.3s;
                line-height: 25px;
                font-weight: 300;

                &:hover {
                    background-color: #e84242;
                    color: #ffffff;
                }
            }

            // &:hover {
            //     background-color: #e84242;

            //     a {
            //         color: #ffffff;
            //     }
            // }
        }

        &:nth-child(3) {
            background-color: black;
        }

        @media only screen and (max-width: 530px) {
            padding: 20px;
        }
    }

    @media only screen and (max-width: 1080px) {
        padding-bottom: 40px;
    }

    @media only screen and (max-width: 980px) {
        padding-top: 92px;
    }
}

@keyframes moveImage {
    0% {
        top: 700px;
    }
    25% {
        top: 715px;
    }
    50% {
        top: 700px;
    }
    75% {
        top: 715px;
    }
    100% {
        top: 700px;
    }
}

@keyframes myMove {
    0% {
        top: 300px;
    }
    25% {
        top: 315px;
    }
    50% {
        top: 300px;
    }
    75% {
        top: 315px;
    }
    100% {
        top: 300px;
    }
}
