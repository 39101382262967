footer {
    position: relative;
    width: 100%;
    background-image: url("../../images/hero_footer.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    .cream-top {
        width: 100%;
        position: relative;
        bottom: 1px;

        @media only screen and (max-width: 328px) {
            bottom: 3px;
        }
    }

    .stay__informed {
        text-align: center;
        padding-top: 90px;

        .logo {
            width: 160px;
        }

        h2 {
            font-size: 40px;
            color: #fff;
        }

        h3 {
            font-size: 22px;
            color: #fff;
            font-weight: 400;
        }

        .email {
            margin-top: 40px;
            margin-bottom: 80px;

            input {
                background-color: #fff;
                padding: 20px 40px;
                padding-top: 23px;
                width: 480px;
                border: none;
                border-radius: 40px;
                outline: none;
                border: 2px solid transparent;
                transition: 0.5s;

                &::placeholder {
                    font-size: 18px;
                }

                &:focus {
                    border: 2px solid #e84242;
                    transition: 0.5s;
                }

                @media only screen and (max-width: 600px) {
                    width: 100%;
                    margin-bottom: 15px;
                    text-align: center;
                }
            }

            button {
                background-color: #e84242;
                color: #fff;
                font-size: 16px;
                padding: 22px 40px;
                border: none;
                border-radius: 40px;
                cursor: pointer;
                font-weight: 700;
                margin-left: -60px;
                transition: 0.3s;

                &:hover {
                    background-color: #ff4949;
                    transition: 0.3s;
                }

                @media only screen and (max-width: 600px) {
                    margin-left: 0;
                    width: 100%;
                }
            }
        }

        @media only screen and (max-width: 980px) {
            padding-top: 22px;
        }
    }

    .footer__top {
        margin-bottom: 40px;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: grid;
            gap: 15px;
            font-size: 14px;
            color: white;
            font-weight: 500;

            div {
                display: flex;
                gap: 10px;
                align-items: center;

                span {
                    font-weight: 700;
                }

                svg {
                    color: #939393;
                    font-weight: 700;
                }
            }
        }

        .social {
            margin-top: 25px;
            gap: 10px;
            display: flex;

            a {
                background-color: #e84242;
                border-radius: 50%;
                width: 26px;
                height: 26px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                padding: 6px;
                transition: 0.3s ease-out;

                &:hover {
                    transition: 0.3s ease-out;
                    background-color: #fff;
                    color: #e84242;
                }
            }
        }

        .footer__titles {
            text-transform: uppercase;
            font-size: 16px;
            color: #e84242;
            margin-bottom: 25px;
        }

        .images {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 5px;

            .image__scale{
                overflow: hidden;

                &:hover{
                    img {
                        scale: 1.2;
                    }
                }
            }

            img {
                width: 100%;
                object-fit: cover;
                transition: 1s ease-out;
                cursor: pointer;

                &:hover {
                    transition: 1s ease-out;
                    filter: brightness(50%);
                }
            }
        }

        .quick__links {
            display: grid;
            gap: 10px;

            a {
                text-decoration: none;
                font-size: 14px;
                color: #fff;
                transition: 0.3s ease-out;
                font-weight: 500;

                &:hover {
                    opacity: 0.5;
                    transition: 0.3s ease-out;
                }
            }
        }
    }

    .footer__bottom {
        display: flex;
        text-align: center;
        color: #fff;
        justify-content: space-between;
        font-size: 12px;
        padding-top: 30px;
        padding-bottom: 30px;
        border-top: 1px solid #fff;

        p {
            margin: 0;

            @media only screen and (max-width: 328px) {
                font-size: 10px;
            }
        }

        .policy__menu {
            display: flex;
            gap: 20px;

            a {
                position: relative;
                text-decoration: none;
                color: #fff;
                transition: 0.3s ease-out;

                &:hover {
                    opacity: 0.5;
                    transition: 0.3s ease-out;

                    &::before {
                        transform: scaleX(0);
                    }
                }

                &::before {
                    content: "";
                    position: absolute;
                    bottom: -3px;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: #fff;
                    transform-origin: left;
                    transform: scaleX(1);
                    transition: transform 0.3s ease-out;
                }

                @media only screen and (max-width: 328px) {
                    font-size: 10px;
                }
            }
        }
    }
}
