.availability{
    position: relative;
    background-color: #f5e7c1;
    padding-top: 100px;
    padding-bottom: 100px;

    img{
        top: -85px;
        position: absolute;
        width: 100%;
    }

    h2{
        font-weight: 400;
        color: #e84242;
        font-family: "Rakkas", serif;
        font-size: 80px;
        line-height: 80px;
        margin: 0;
        margin-bottom: 32px;

        @media only screen and (max-width: 500px) {
            font-size: 56px;
            line-height: 56px;
        }
    }

    span{
        color: #000;
        font-weight: 700;
        font-size: 20px;
    }

    p{
        line-height: 24px;

        span{
            font-weight: 700;
            font-size: 16px;
            margin-bottom: 8px;
        }
    }

    iframe{
        padding: 16px;
        background-color: #fff;
        border-radius: 30px;
        width: 100%;

        @media only screen and (max-width: 500px) {
            max-height: 350px;
        }
    }

    h6{
        margin-bottom: 16px;
        color: #000;
        font-weight: 700;
        font-size: 20px;
        margin-top: 0;
    }

    .phone-number{
        font-size: 30px;
        font-weight: 700;
        color: #e84242;
        margin: 0;
        margin-bottom: 32px;
        margin-top: 12px;
    }

    @media only screen and (max-width: 980px) {
        padding-top: 38px;
        padding-bottom: 38px;
    }
}