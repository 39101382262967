.header__desktop {
    // margin: 16px;
    // width: calc(100% - 32px);
    display: inline-flex;
    justify-content: space-between;
    // background: aqua;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px;
    // position: absolute;
    top: 0;
    z-index: 1000;
    height: 90px;
    // border-radius: 50px;
    transition: height 0.5s ease;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px;
    width: 100%;
    position: fixed;

    .navmenu {
        display: inline-flex;
        gap: 50px;

        a {
            display: flex;
            align-items: center;

            .logo {
                height: 65px;
            }
        }

        ul {
            list-style-type: none;
            display: inline-flex;
            gap: 30px;
            padding: 0;
            margin: 0;
            align-items: center;

            li {
                position: relative;

                a {
                    font-size: 16px;
                    text-transform: uppercase;
                    text-decoration: none;
                    font-weight: 600;
                    color: #121212;
                    transition: all 200ms ease;
                    line-height: 60px;
                    position: relative;

                    &:hover {
                        color: #e84242 !important;

                        &::before {
                            content: "";
                            position: absolute;
                            width: 8px;
                            height: 8px;
                            background-color: #e84242;
                            border-radius: 50%;
                            bottom: 10px; // Módosítva a kiindulási érték
                            transition: all 0.3s ease; // Módosított hozzáadott transition
                            left: 45%;
                        }

                        &:hover::before {
                            bottom: 10px; // Módosítva a hover állapotban
                        }
                    }

                    &:visited {
                        color: #121212;
                    }
                }

                .active {
                    &::before {
                        content: "";
                        position: absolute;
                        width: 8px;
                        height: 8px;
                        background-color: #e84242;
                        border-radius: 50%;
                        bottom: 10px; // Módosítva a kiindulási érték
                        transition: all 0.3s ease; // Módosított hozzáadott transition
                        left: 45%;
                    }
                }
            }
        }
    }

    .top-bar {
        justify-content: space-between;
        display: inline-flex;
        align-items: center;
        gap: 30px;

        a {
            text-transform: uppercase;
            text-decoration: none;
            padding: 17px 34px;
            font-weight: 600;
            color: #e84242;
            border-radius: 50px;
            font-size: 16px;
            margin-top: 8px;
            margin-bottom: 8px;
            border: 2px solid #e84242;
            transition: all 300ms ease;

            &:hover {
                background-color: #e84242;
                color: #ffffff;
            }
        }

        .order {
            p {
                display: block;
                line-height: normal;
                margin: 0;
                text-align: right;
                font-weight: 600;
                font-size: 24px;
                color: #e84242;

                &:first-child {
                    font-size: 16px;
                    margin-bottom: 8px;
                    color: #121212;
                }
            }
        }

        .quantity {
            padding: 12px 8px;
            background-color: #f1f1f1;
            border-radius: 20px;
            cursor: pointer;
            font-weight: 500;

            .cart-quantity {
                width: 25px;
                text-align: center;
                display: inline-block;
            }

            &:hover {
                .basket {
                    color: #e84242;
                    transition: all 300ms ease;
                }
            }
        }
    }

    .dropdown {
        display: none;
    }

    .dropdownOpen {
        display: block;
        position: absolute;
        right: 250px;
        background: white;
        top: 75px;
        width: 275px;
        padding: 16px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 10px 0px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;

        ul {
            list-style-type: none;
            padding: 0;
            overflow: hidden;
            overflow-y: scroll;
            max-height: 265px;

            li {
                margin-bottom: 15px;

                .cart-item {
                    display: flex;
                    align-items: center;
                    position: relative;
                    gap: 5px;

                    img {
                        height: 65px;
                        width: 65px;
                    }

                    .cart-item-details {
                        p {
                            margin: 0;
                        }

                        .item-name {
                            font-size: 15px;
                            font-weight: 800;
                            margin-bottom: 5px;
                        }

                        .item-quantity-price {
                            font-size: 13px;
                            font-weight: 600;
                        }
                    }
                }

                button {
                    position: absolute;
                    right: -3px;
                    top: 5px;
                    border: none;
                    background: transparent;
                    padding: 0;
                    cursor: pointer;
                    font-size: 18px;
                }
            }

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .subtotal {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid #e7e7e7;
        }

        .buttons {
            display: flex;
            justify-content: space-between;

            a {
                border: none;
                cursor: pointer;
                padding: 12px 18px;
                border-radius: 30px;
                text-transform: uppercase;
                font-weight: 600;
                color: #fff;
                background-color: #e84242;
                box-shadow: 0 0 0 4em #e84242 inset;
                transition: 0.3s;
                font-size: 13px;
                text-decoration: none;

                &:hover {
                    background-color: #ff4949;
                    transition: 0.3s;
                }
            }
        }
    }

    @media only screen and (max-width: 980px) {
        display: none;
    }
}

.header__mobile {
    display: none;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    gap: 15px;
    position: fixed;
    width: 100%;
    background: white;
    z-index: 3;

    .hamburger {
        height: 50px;
        width: 50px;
        padding: 8px;
        cursor: pointer;
    }

    a {
        height: 50px;
        width: 50px;

        .logo {
            height: 50px;
        }
    }

    .mobile__menu {
        background: #fff;
        position: absolute;
        top: 50px;
        border-radius: 15px;
        width: 250px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        z-index: 1;
        height: 0;
        overflow: hidden;
        transition: 0.5s;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                text-align: center;
                padding: 16px 32px;
                border-bottom: 1px solid #d7d7d7;

                a {
                    text-decoration: none;
                    color: #121212;
                    font-weight: 600;
                    text-transform: uppercase;
                    transition: 0.3s;
                }

                .active {
                    color: #e84242;
                }

                &:hover {
                    cursor: pointer;
                    a {
                        transition: 0.3s;
                        color: #e84242;
                    }
                }
            }
        }

        .order {
            margin-top: 60px;

            p {
                display: block;
                line-height: normal;
                margin: 0;
                text-align: center;
                font-weight: 600;
                font-size: 24px;
                color: #e84242;
                margin-bottom: 32px;

                &:first-child {
                    font-size: 16px;
                    margin-bottom: 8px;
                    color: #121212;
                    line-height: normal;
                    font-weight: 600;
                    text-align: center;
                }
            }
        }

        .quantity__container {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 50px;

            a {
                color: #121212;
                text-decoration: none;

                .quantity {
                    width: 50px;
                    display: flex;
                    gap: 3px;
                    height: 50px;
                    align-items: center;
                    justify-content: center;
                    background-color: #f1f1f1;
                    border-radius: 20px;
                    padding: 8px;

                    &:hover {
                        .basket {
                            color: #e84242;
                            transition: all 300ms ease;
                        }
                    }
                }
            }
        }

        .full__menu {
            text-transform: uppercase;
            text-decoration: none;
            padding: 17px 29px;
            font-weight: 600;
            color: #e84242;
            border-radius: 50px;
            font-size: 16px;
            margin-top: 8px;
            margin-bottom: 8px;
            border: 2px solid #e84242;
            transition: all 300ms ease;

            &:hover {
                background-color: #e84242;
                color: #ffffff;
            }
        }
    }

    .open {
        height: 480px;
        transition: 0.5s;
        padding-bottom: 45px;
        padding: 25px;
        padding-top: 10px;
    }

    @media only screen and (max-width: 980px) {
        display: flex !important;
    }
}
