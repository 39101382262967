@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rakkas&display=swap");

* {
    font-family: "Inter", Arial, Helvetica, sans-serif;
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.center {
    text-align: center;
}

.wrapper-container {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 1200px) {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.Toastify__toast {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px !important;
    box-shadow:
        rgba(0, 0, 0, 0.25) 0px 54px 55px,
        rgba(0, 0, 0, 0.12) 0px -12px 30px,
        rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px,
        rgba(0, 0, 0, 0.09) 0px -3px 5px !important;

    @media only screen and (max-width: 480px) {
        width: calc(100% - 20px);
    }
}

.row {
    width: calc(100% + 19px);
    max-width: unset;
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;

    .col-100 {
        flex: 0 0 auto;
        width: calc(100% - 30px);
        margin-left: 15px;
        margin-right: 15px;

        @media (max-width: 998px) {
            flex: 0 0 auto;
            width: calc(100% - 30px);
        }

        * {
            max-width: 100%;
        }
    }

    .col-75 {
        flex: 0 0 auto;
        width: calc(75% - 30px);
        margin-left: 15px;
        margin-right: 15px;

        @media (max-width: 998px) {
            flex: 0 0 auto;
            width: calc(100% - 30px);
        }

        * {
            max-width: 100%;
        }
    }

    .col-60 {
        flex: 0 0 auto;
        width: calc(60% - 30px);
        margin-left: 15px;
        margin-right: 15px;

        @media (max-width: 998px) {
            flex: 0 0 auto;
            width: calc(100% - 30px);
        }

        * {
            max-width: 100%;
        }
    }

    .col-50 {
        flex: 0 0 auto;
        width: calc(50% - 30px);
        margin-left: 15px;
        margin-right: 15px;

        @media (max-width: 998px) {
            flex: 0 0 auto;
            width: calc(100% - 30px);
        }

        * {
            max-width: 100%;
        }
    }

    .col-40 {
        flex: 0 0 auto;
        width: calc(40% - 30px);
        margin-left: 15px;
        margin-right: 15px;

        @media (max-width: 998px) {
            flex: 0 0 auto;
            width: calc(100% - 30px);
        }

        * {
            max-width: 100%;
        }
    }

    .col-33 {
        flex: 0 0 auto;
        width: calc(33.33% - 30px);
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 15px;
        margin-bottom: 15px;

        @media (max-width: 998px) {
            flex: 0 0 auto;
            width: calc(100% - 30px);
        }

        @media (max-width: 800px) {
            flex: 0 0 auto;
            width: calc(100% - 30px);
        }

        * {
            max-width: 100%;
        }
    }

    .col-66 {
        flex: 0 0 auto;
        width: calc(66.66% - 30px);
        margin-left: 15px;
        margin-right: 15px;

        @media (max-width: 998px) {
            flex: 0 0 auto;
            width: calc(100% - 30px);
        }

        @media (max-width: 800px) {
            flex: 0 0 auto;
            width: calc(100% - 30px);
        }

        * {
            max-width: 100%;
        }
    }

    .col-25 {
        flex: 0 0 auto;
        width: calc(25% - 30px);
        margin-left: 15px;
        margin-right: 15px;

        @media (max-width: 998px) {
            flex: 0 0 auto;
            width: calc(50% - 30px);
        }

        @media (max-width: 800px) {
            flex: 0 0 auto;
            width: calc(100% - 30px);
        }
    }

    .col-20 {
        flex: 0 0 auto;
        width: calc(20% - 30px);
        margin-left: 15px;
        margin-right: 15px;

        @media (max-width: 998px) {
            flex: 0 0 auto;
            width: calc(50% - 30px);
            margin-bottom: 20px;
        }

        @media (max-width: 800px) {
            flex: 0 0 auto;
            width: calc(100% - 30px);
        }
    }

    .col-30 {
        flex: 0 0 auto;
        width: calc(30% - 30px);
        margin-left: 15px;
        margin-right: 15px;

        @media (max-width: 998px) {
            flex: 0 0 auto;
            width: calc(50% - 30px);
        }

        @media (max-width: 800px) {
            flex: 0 0 auto;
            width: calc(100% - 30px);
        }
    }

    .flex {
        display: flex;
    }

    .space-between {
        justify-content: space-between;
    }
}
