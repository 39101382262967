.about {
    .about__hero {
        width: 100%;
        background-image: url("../../images/hero_about.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        height: 700px;
        position: relative;

        .hero__title {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 700px;

            h1 {
                font-size: 110px;
                font-weight: 400;
                font-family: "Rakkas", serif;
                color: #fff;
                text-align: center;
                margin: 0;
                margin-left: 15px;
                margin-right: 15px;

                @media only screen and (max-width: 846px) {
                    font-size: 90px;
                    line-height: 90px;
                    margin-bottom: 35px;
                }

                @media only screen and (max-width: 328px) {
                    font-size: 65px;
                    line-height: 65px;
                }
            }

            p {
                font-size: 26px;
                color: #fff;
                text-align: center;
                margin: 0;
                margin-left: 15px;
                margin-right: 15px;

                @media only screen and (max-width: 846px) {
                    font-size: 24px;
                }
            }
        }

        .redbottom {
            position: absolute;
            bottom: 0;
            width: 100%;

            @media only screen and (max-width: 328px) {
                bottom: -2px;
            }
        }
    }

    .flipping {
        background-color: #e84242;
        padding-top: 50px;
        padding-bottom: 50px;

        h2 {
            font-size: 80px;
            text-align: center;
            color: #fff;
            font-family: "Rakkas", serif;
            margin-left: 15px;
            margin-right: 15px;

            @media only screen and (max-width: 328px) {
                font-size: 75px;
            }
        }

        .flipping-container {
            max-width: 930px;
            margin-left: auto;
            margin-right: auto;

            .row {
                .card {
                    background-color: #fff;
                    padding: 64px 32px;
                    border-radius: 30px;
                    text-align: center;

                    img {
                        height: 64px;
                        width: 64px;
                        object-fit: contain;

                        &:hover {
                            opacity: 0;
                        }
                    }

                    h3 {
                        font-size: 20px;
                        color: #e84242;
                    }
                }

                @media only screen and (max-width: 948px) {
                    width: 100%;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }

    .about__us {
        background-color: #f5e7c1;
        padding-bottom: 120px;

        h1 {
            margin: 0;
            font-size: 24vw;
            text-align: center;
            font-family: "Rakkas", serif;
            color: #e84242;
            margin-bottom: -11vw;
            margin-left: 15px;
            margin-right: 15px;
        }

        .big__card {
            padding: 64px;
            background-color: #fff;
            margin-left: 25px;
            margin-right: 25px;
            border-radius: 40px;
            display: flex;
            justify-content: space-between;
            gap: 90px;
            margin-bottom: 1px;
            position: relative;

            img {
                max-width: 560px;
                border-radius: 40px;
            }

            .desc {
                display: flex;
                align-items: center;

                h3 {
                    font-family: "Rakkas", serif;
                    color: #e84242;
                    font-size: 110px;
                    line-height: 110px;
                    margin-bottom: 20px;
                    margin-top: 0;

                    @media only screen and (max-width: 1326px) {
                        margin-top: 20px;
                    }

                    @media only screen and (max-width: 680px) {
                        font-size: 70px;
                        line-height: 70px;
                    }

                    @media only screen and (max-width: 430px) {
                        font-size: 50px;
                        line-height: 50px;
                    }

                    @media only screen and (max-width: 328px) {
                        font-size: 40px;
                        line-height: 40px;
                    }
                }

                .flex {
                    display: flex;

                    p {
                        line-height: 28px;
                        padding-left: 10px;
                        padding-right: 10px;

                        @media only screen and (max-width: 1326px) {
                            padding-left: 0;
                        }

                        @media only screen and (max-width: 328px) {
                            font-size: 14px;
                            line-height: 18px;
                        }
                    }

                    .strong {
                        font-weight: 700;

                        @media only screen and (max-width: 328px) {
                            font-size: 14px;
                            line-height: 18px;
                        }
                    }

                    ul {
                        list-style-type: none;
                        margin: 0;
                        padding: 0;
                        margin-left: 10px;

                        li {
                            margin-bottom: 16px;
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            font-size: 14px;

                            svg {
                                font-size: 18px;
                                color: #e84242;
                            }
                        }
                    }

                    @media only screen and (max-width: 1326px) {
                        display: block;
                    }
                }
            }

            .flex {
                display: flex;
                justify-content: center;

                img {
                    @media only screen and (max-width: 740px) {
                        width: 100%;
                    }
                }
            }

            @media only screen and (max-width: 1326px) {
                display: block;
            }

            @media only screen and (max-width: 650px) {
                padding: 30px;
            }
        }

        .redtop {
            width: 100%;
            top: -1px;
            position: relative;

            @media only screen and (max-width: 328px) {
                top: -3px;
            }
        }
    }
}
