.shipping {
    background-color: #f5e7c1;
    padding-top: 128px;
    padding-bottom: 128px;
    position: relative;

    .start {
        display: flex;
        justify-content: flex-start;

        .takeAway {
            width: 100%;
        }

        .content {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            max-width: 500px;

            img {
                width: 75px;
            }

            h1 {
                font-family: "Rakkas", serif;
                font-size: 70px;
                color: #e84242;
                font-weight: 400;
                margin: 0;
                margin-top: 15px;
                line-height: 70px;
                margin-bottom: 30px;

                @media only screen and (max-width: 550px) {
                    font-size: 52px;
                    line-height: 52px;
                }
            }

            .stepline {
                &::before {
                    content: ".....";
                    font-size: 2.5em;
                    line-height: 0.25;
                    word-break: break-word;
                    height: 1.8em;
                    width: 1rem;
                    text-align: center;
                    margin-left: 1em;
                    margin-top: 0;
                    display: block;
                    color: #e84242;
                }
            }

            .flex {
                display: flex;
                gap: 20px;

                .ico {
                    width: 100px;
                    height: 100px;
                    background: white;
                    border-radius: 50%;
                }

                img {
                    padding: 23px;
                    object-fit: contain;
                    width: 100px !important;
                }

                svg {
                    color: red;
                    background: white;
                    border-radius: 50%;
                    width: 100px;
                    height: 100px;
                    padding: 25px;
                }

                h2 {
                    font-family: "Inter", Arial, Helvetica, sans-serif;
                    font-size: 20px;
                    font-weight: 600;
                    margin-bottom: 8px;

                    @media only screen and (max-width: 506px) {
                        font-size: 18px;
                    }
                }

                p {
                    font-size: 16px;
                    margin: 0;

                    @media only screen and (max-width: 506px) {
                        font-size: 13px;
                    }
                }
            }

            @media only screen and (max-width: 1130px) {
                padding-left: 15px;
                padding-right: 15px;
            }

            @media only screen and (max-width: 880px) {
                max-width: fit-content;
                margin-top: 25px;
            }
        }

        @media only screen and (max-width: 880px) {
            display: block;
        }
    }

    .creamtop {
        position: absolute;
        bottom: -85px;
    }

    @media only screen and (max-width: 980px) {
        padding-top: 58px;
        padding-bottom: 58px;
    }
}
