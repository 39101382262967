.review {
    padding-top: 192px;
    padding-bottom: 192px;
    background-color: #e84242;
    position: relative;

    .creamtop {
        position: absolute;
        width: 100%;
        top: -1px;
    }

    h1 {
        font-size: 24vw;
        margin: 0;
        text-align: center;
        font-family: "Rakkas", serif;
        line-height: 400px;
        color: #f5e7c1;
        position: absolute;
        width: 100%;
        z-index: 1;
        margin-top: 125px;

        @media only screen and (max-width: 830px) {
            line-height: normal;
        }

        @media only screen and (max-width: 767px) {
            margin-top: 65px;
        }
    }

    .burger {
        position: relative;
        max-width: 100%;
    }

    .w-100 {
        width: 100%;

        img{
            @media only screen and (max-width: 998px) {
                max-width: 570px;
                width: 100%;
            }
        }

        @media only screen and (max-width: 998px) {
            text-align: center;
            margin-bottom: 40px;
        }
    }

    .row {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        align-items: center;

        h2 {
            color: #f5e7c1;
            font-family: "Rakkas", serif;
            margin: 0;
            font-size: 60px;
            font-weight: 400;
            line-height: 60px;

            @media only screen and (max-width: 998px) {
                margin-top: 20px;
                font-size: 50px;
                line-height: 50px;
            }
        }

        p {
            color: #f5e7c1;
            font-size: 16px;
            font-weight: 400;

            @media only screen and (max-width: 998px) {
                margin-top: 25px;
            }
        }

        &:nth-child(even) {
            flex-direction: row-reverse;
        }
    }

    @media only screen and (max-width: 980px) {
        padding-top: 62px;
        padding-bottom: 12px;
    }
}
