.slide-content {
    max-width: 1160px;
    margin-left: auto;
    margin-right: auto;

    h2 {
        color: #f5e7c1;
        font-size: 100px;
        font-weight: 400;
        font-family: "Rakkas", serif;
        line-height: 100px;
        margin-top: 220px;
        max-width: 600px;

        @media only screen and (max-width: 656px) {
            margin-top: 110px;
            font-size: 80px;
            line-height: 80px;
        }

        @media only screen and (max-width: 516px) {
            font-size: 62px;
        }
    }

    .text-image {
        max-width: 500px;
        display: flex;
        gap: 20px;

        img {
            @media only screen and (max-width: 404px) {
                max-height: 85px;
            }
        }

        p {
            color: white;
            font-size: 16px;
            color: white;
            line-height: 16px;
            display: flex;
            align-items: center;
        }
    }
}

.slide {
    flex: 0 0 auto;
    height: 1000px;
    position: relative;
    background-size: cover;
    background-position: center;
    transition: opacity 0.5s ease;

    .redtop {
        top: -1px;
        position: absolute;
        width: 100%;

        @media only screen and (max-width: 1200px) {
            margin-left: -15px;
            margin-right: -15px;
        }
    }

    @media only screen and (max-width: 1200px) {
        padding-left: 15px;
        padding-right: 15px;
    }

    @media only screen and (max-width: 1000px) {
        height: 800px;
    }

    @media only screen and (max-width: 656px) {
        height: 600px;
    }
}
.slider {
    position: relative;
    overflow: hidden;
    height: 1000px; // Adjust height as needed
    width: 100%;

    .slides-container {
        display: flex;
        transition: transform 0.5s ease;
    }

    @media only screen and (max-width: 1000px) {
        height: 800px;
    }

    @media only screen and (max-width: 656px) {
        height: 600px;
    }
}

.slider-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.slider-buttons {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
}

.slider-button {
    background-color: transparent;
    border: none;
    font-size: 24px;
    color: white;
    cursor: pointer;
    transition: color 0.3s ease;
}

.slider-button:hover {
    color: #000000; /* vagy más szín */
}

.slider-buttons {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .slider-button {
        position: absolute;
    }

    .left {
        left: 16px;
        border: 1px solid rgb(255, 255, 255);
        line-height: 5px;
        height: 40px;
        font-size: 16px;
        width: 40px;
        padding: 0;
        /* font-size: 20px; */
        /* padding: 8px; */
        border-radius: 50%;
        transition: 0.3s;

        &:hover {
            background-color: #fff;
        }
    }

    .right {
        right: 16px;
        border: 1px solid rgb(255, 255, 255);
        line-height: 5px;
        height: 40px;
        font-size: 16px;
        width: 40px;
        padding: 0;
        /* font-size: 20px; */
        /* padding: 8px; */
        border-radius: 50%;
        transition: 0.3s;

        &:hover {
            background-color: #fff;
        }
    }
}

.dots {
    display: flex;
    gap: 8px;
    position: absolute;
    text-align: center;
    justify-content: center;
    align-items: center;
    // left: 48%;
    bottom: 100px;
    width: 100%;
}

.dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ffffff;
    cursor: pointer;
    transition: 0.3s;
}

.dot.active {
    background-color: #f0f0f0; /* vagy más szín */
    width: 24px;
    border-radius: 10px;
}

.creambottom {
    width: 100%;
    position: absolute;
    bottom: -1px;

    @media only screen and (max-width: 1200px) {
        margin-right: -15px;
        margin-left: -15px;
    }
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
