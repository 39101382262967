.page-404 {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    background-color: #f5e7c1;

    .nopage {
        text-align: center;

        h1 {
            font-family: "Rakkas", serif;
            color: #ff4949;
            font-size: 60px;
            margin-bottom: 14px;

            @media only screen and (max-width: 470px) {
                font-size: 48px;
            }
        }

        p {
            font-weight: 600;
            margin-bottom: 50px;

            @media only screen and (max-width: 470px) {
                font-size: 14px;
            }
        }

        a {
            text-transform: uppercase;
            background-color: #e84242;
            font-size: 14px;
            font-weight: 600;
            color: #fff;
            padding: 16px 22px;
            border-radius: 50px;
            cursor: pointer;
            text-decoration: none;
            transition: 0.3s;

            &:hover {
                background-color: #ff4949;
                transition: 0.3s;
            }
        }
    }

    @media only screen and (max-width: 470px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}
